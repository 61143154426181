<template>
  <page-title
    video_url="https://www.youtube.com/embed/8Qnqj1WYR9A"
    :skeleton="false"
    :tooltip="$t('commission.title')"
    :title="$t('commission.title')"
  />
  <docs-required />

  <q-tabs
    style="max-width: 400px"
    no-caps
    class="q-mt-lg q-mb-lg default-rounded"
    v-model="tab"
    dense
    align="justify"
    narrow-indicator
  >
    <q-tab
      name="opened"
      :ripple="false"
      class="text-uppercase"
      content-class="text-tab-bold"
      :label="$t('commission.tab_opened')"
    />
    <q-tab
      name="closed"
      :ripple="false"
      class="text-uppercase"
      content-class="text-tab-bold"
      :label="$t('commission.tab_closed')"
    />
  </q-tabs>

  <q-tab-panels class="bg-transparent" v-model="tab" animated>
    <q-tab-panel class="no-scroll" name="opened">
      <commission-opened-tab />
    </q-tab-panel>
    <q-tab-panel class="no-scroll" name="closed">
      <commission-closed-tab />
    </q-tab-panel>
  </q-tab-panels>
</template>

<script>
import CommissionOpenedTab from "@/modules/main/pages/commission/components/commissionOpenedTab";
import CommissionClosedTab from "@/modules/main/pages/commission/components/commissionClosedTab";
import docsRequired from "@/modules/main/pages/commission/components/docsRequired";
import { getCurrentTab, deleteCurrentTab } from "@/modules/partner/storage";
import PageTitle from "@/modules/main/components/page-title";
import { ref, onMounted } from "vue";

export default {
  name: "index",

  components: {
    CommissionClosedTab,
    CommissionOpenedTab,
    docsRequired,
    PageTitle,
  },

  setup() {
    const tab = ref("opened");

    onMounted(() => {
      if (getCurrentTab()) {
        tab.value = getCurrentTab();
        deleteCurrentTab();
      }
    });

    return {
      tab,
    };
  },
};
</script>
